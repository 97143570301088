// fields to be set from java
class ZIDXAccountListingIndexFileFields implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountListingIndexFileFieldsContainer";
    }

    getMatchURL() {
        return "/account/data-feeds/listing-index/file/fields";
    }

    render() {
        // console.log("listing index file fields");
        window.ZIDXOptions.loadFunctions.push(function () {
            const $ = ZIDX.$;
            $(".zidxPageActive #listing_index_file_field_name").on("change", function(this:HTMLSelectElement){
                let value=this.options[this.selectedIndex].value;
                let fileId=$(this).attr("data-file-id");
                if(value!=""){
                    let link="/account/data-feeds/listing-index/file/fields/edit?listing_index_file_id="+fileId+"&listing_index_file_field_name="+escape(value);
                    window.location.href=link;
                }
            });
            $('#autogenerateFields').click(function (this: HTMLAnchorElement, e: ClickEvent) {
                e.preventDefault();
                let r = confirm("Are you sure to generate elements automatically?");
                if (r) {
                    ZIDX.loadPushStateURL($(this).attr("href"), false)
                }
            })
            $('#autogenerateDeleteAll').click(function (this: HTMLAnchorElement, e: ClickEvent) {
                e.preventDefault();
                let r = confirm("Are you sure to delete all elements?");
                if (r) {
                    ZIDX.loadPushStateURL($(this).attr("href"), false)
                }
            })
        })
    }
}